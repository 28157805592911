import React, { useState } from 'react'
import { useRouter } from 'next/router';
import { IoIosArrowDown } from 'react-icons/io';

export const LangSwitcher = () => {

    const { locale: currentLocale, locales, asPath, query, pathname, ...router } = useRouter();

    const changeLanguage = locale => {
        setIsOpen(false);
        // hack
        setTimeout(() => router.push({
            pathname,
            query,
        }, asPath, { locale }), 200)
    };

    const [isOpen, setIsOpen] = useState(false);
    const toggleDropDown = () => setIsOpen(!isOpen);


    return (
        <>
            <div className="hidden lg:block">
                <button className="flex items-center w-6 space-x-1 lg:ml-4 focus:outline-none" onClick={toggleDropDown}>
                    <span className="text-sm capitalize" >{currentLocale}</span>
                    <IoIosArrowDown className="flex-shrink-0 text-sm" />
                </button>
                <div className={`${isOpen ? 'block' : 'hidden'} absolute px-4 py-3 mt-4 bg-white border border-gray-400 rounded-lg`}>
                    {locales.filter(l => l !== currentLocale)
                        .map((locale) => (
                            <a className="block py-1 cursor-pointer" key={locale} onClick={() => changeLanguage(locale)}>
                                <span className="text-sm capitalize" >{locale}</span>
                            </a>
                        ))}
                </div>
            </div>
            <div className="flex mt-8 space-x-6 lg:hidden">
                {locales.map((locale) => (
                    <a className={`${currentLocale === locale ? 'font-bold border-black' : 'font-normal border-transparent'} py-2 px-4 border rounded  block `} key={locale} onClick={() => changeLanguage(locale)}>
                        <span className="text-sm capitalize" >{locale}</span>
                    </a>
                ))}
            </div>
        </>
    )
}
